<template>
  <ion-card class="vertical-space-card d-flex text-black clickable-item">
    <div class="clickable-item">
      <router-link :to="{ name: 'social-space-details', params: { slug: space.slug } }">
        <img loading="lazy" v-image :src="resizeUpload(space.cover_image, '250x250')" />
      </router-link>
    </div>
    <div class="flex-grow-1" :class="[isMobSmallScreen ? 'p-1 pt-2' : 'p-2']">
      <router-link :to="{ name: 'social-space-details', params: { slug: space.slug } }">
        <div>
          <div class="d-flex justify-content-between">
            <router-link :to="{ name: 'social-space-details', params: { slug: space.slug } }">
              <div class="name ml-2 d-flex flex-row text-black clickable-item-hov">{{ space.name }}</div>
            </router-link>
          </div>
          <div
            v-if="space.description"
            class="d-flex description ml-2 flex-row text-black"
            :class="[isMobSmallScreen ? 'mt-1' : 'mt-2']"
          >
            {{
              isMobSmallScreen
                ? truncateText(stripHtmlTags(space.description), 50)
                : truncateText(stripHtmlTags(space.description), 200)
            }}
          </div>
        </div>
      </router-link>
      <div
        class="d-flex justify-content-end actions"
        :class="[
          space.description && !isMobSmallScreen
            ? 'set-big-screen-margin'
            : space.description && isMobSmallScreen
            ? 'mt-0 flex-wrap'
            : !space.description && isMobSmallScreen
            ? 'set-small-screen-margin flex-wrap'
            : 'set-margin ',
        ]"
      >
        <Reaction
          type="world"
          :reactions="space.reaction_counts"
          :user-reaction="space.user_reaction"
          :left-indent-popup="isMobSmallScreen ? index % 2 === 0 : index % 3 === 0"
          @click.stop
          @changed="(reaction) => reactionChanged(reaction.key, space, reaction.isInstant)"
        >
          <ion-button color="transparent" class="inline-button icon-button clickable d-file">
            <div class="d-flex align-items-center justify-content-center">
              <i
                class="text-primary mr-1 ti-thumb-up"
                :class="{
                  'text-secondary': !!space.user_reaction && space.user_reaction.reaction,
                }"
              />
              <span
                class="reaction-count"
                :class="[!!space.user_reaction && space.user_reaction.reaction ? 'text-secondary' : 'text-primary']"
                >{{ get(space, 'reaction_counts.total_count', '') || 0 }}</span
              >
            </div>
          </ion-button>
        </Reaction>

        <ion-button color="transparent" class="inline-button icon-button ml-2">
          <div class="d-flex align-items-center justify-content-center">
            <ion-icon class="text-primary mr-1 user-icon" :icon="peopleOutline" />
            <span color=" #3dc2ff" class="reaction-count text-primary">{{ memberCounts }}</span>
          </div>
        </ion-button>

        <ion-button color="transparent" class="inline-button icon-button ml-2">
          <div class="d-flex align-items-center justify-content-center">
            <div class="chat text-black">Chats:</div>
            <span color=" #3dc2ff" class="reaction-count text-black ml-1">{{ roomCounts }}</span>
          </div>
        </ion-button>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts" setup>
import { peopleOutline } from 'ionicons/icons';
import { stripHtmlTags } from '@/shared/utils/string';
import store from '@/shared/store';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateText } from '@/shared/utils/string';
import Reaction from '@/shared/components/Reaction/index.vue';

const emits = defineEmits(['reaction']);

const props = defineProps({
  space: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  memberCounts: {
    type: Number,
  },
  roomCounts: {
    type: Number,
  },
});

const reactionChanged = async (reaction: string, social: any, isInstant = false) => {
  const reactionResp = await store.dispatch('WorldsModule/react', { reaction, world: social, isInstant });
  emits('reaction', { reactResp: reactionResp, social });
};

const { width: windowWidth } = useWindowSize();

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 500;
});
</script>

<style lang="sass" scoped>
.set-small-screen-margin
  margin-top: 54px
  position: absolute
.set-margin
  margin-top: 72px
.set-big-screen-margin
  margin-top: 16px
.dark .actions
  color: #FFF !important
  fill: #FFF !important
  stroke: #FFF !important

.badge
  font-size: 14px
  border-radius: 10px

  span
    font-size: 11px
    font-weight: normal
.space-button
  height: 26px
  text-transform: none
  --border-radius: 10px
.back-button
  --background: #8168b7
  --border-radius: 10px
  height: 34px
.icon
  font-size: 14px
.title
  font-size: 24px
  font-weight: bold
.space-info
  width: calc(100% - 93px)
.dark
  .vertical-space-card
    background-color: #1e1e1e
.vertical-space-card
  height: 150px
  border-radius: 10px
  background-color: white
  img
    min-width: 240px !important
    width: 240px !important
    min-height: 150px !important
    height: 150px !important
    background-color: #fff
    object-fit: cover
    border-top-left-radius: 10px
    border-bottom-left-radius: 10px
  .name
    font-size: 18px
    font-weight: bold
    overflow-y: hidden
    overflow-x: hidden
    word-break: break-word
    @media(max-width: 500px)
      font-size: 14px

  .description
    font-size: 14px
    flex-grow: 1
    overflow: hidden
    word-break: break-word

@media(max-width: 800px)
  .description
    height: 50px !important
    max-height: 50px !important
    overflow: hidden
  .vertical-space-card
    img
      min-width: 150px !important
      width: 150px !important
      background-color: #fff
      object-fit: cover
      border-top-left-radius: 10px
      border-bottom-left-radius: 10px
</style>
